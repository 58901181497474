import { useLocation, Redirect } from "react-router-dom";
import { motion } from "framer-motion";
import {
  ProjectsDetailsData1,
  ProjectsDetailsData2,
  ProjectsDetailsData3,
  ProjectsDetailsData4,
  ProjectsDetailsData5,
  ProjectsDetailsData6,
  ProjectsDetailsData7,
  ProjectsDetailsData8,
  ProjectsDetailsData9,
  ProjectsDetailsData10,
  ProjectsDetailsData11,
  ProjectsDetailsData12,
  ProjectsDetailsData13,
  ProjectsDetailsData14,
  ProjectsDetailsData15,
  ProjectsDetailsData16,
  ProjectsDetailsData17,
  ProjectsDetailsData18,
  ProjectsDetailsData19,
  ProjectsDetailsData20,
} from '../components/index';

const ProjectsDetails = (props) => {

  let sH = document.documentElement.clientHeight;
  const mainStyle = {
    height: (sH - 90) + 'px',
  };

  let location = useLocation();
  // console.log(location);
  // console.log(location.search);
  let parameters = location.search;
  parameters = parameters.substr(parameters.indexOf('=') + 1);
  parameters = Number(parameters);
  console.log(parameters);

  return (
    <>
      <motion.div
        className={props.pageTransition.className}
        style={props.pageTransition.style}
        animate={props.pageTransition.animate}
        initial={props.pageTransition.initial}
        exit={props.pageTransition.exit}
        transition={props.pageTransition.transition}
      >
        <main className="mainProjectsDetails" style={mainStyle}>
          <section className="projects_wrap">

            {(() => {
              if (parameters === 1) {
                return <ProjectsDetailsData1 />
              } else if(parameters === 2){
                return <ProjectsDetailsData2 />
              } else if(parameters === 3){
                return <ProjectsDetailsData3 />
              } else if(parameters === 4){
                return <ProjectsDetailsData4 />
              } else if(parameters === 5){
                return <ProjectsDetailsData5 />
              } else if(parameters === 6){
                return <ProjectsDetailsData6 />
              } else if(parameters === 7){
                return <ProjectsDetailsData7 />
              } else if(parameters === 8){
                return <ProjectsDetailsData8 />
              } else if(parameters === 9){
                return <ProjectsDetailsData9 />
              } else if(parameters === 10){
                return <ProjectsDetailsData10 />
              } else if(parameters === 11){
                return <ProjectsDetailsData11 />
              } else if(parameters === 12){
                return <ProjectsDetailsData12 />
              } else if(parameters === 13){
                return <ProjectsDetailsData13 />
              } else if(parameters === 14){
                return <ProjectsDetailsData14 />
              } else if(parameters === 15){
                return <ProjectsDetailsData15 />
              } else if(parameters === 16){
                return <ProjectsDetailsData16 />
              } else if(parameters === 17){
                return <ProjectsDetailsData17 />
              } else if(parameters === 18){
                return <ProjectsDetailsData18 />
              } else if(parameters === 19){
                return <ProjectsDetailsData19 />
              } else if(parameters === 20){
                return <ProjectsDetailsData20 />
              } else {
                return <Redirect to="/projects"/>
              }
            })()}

          </section>
        </main>
      </motion.div>
    </>
  );
}

export default ProjectsDetails;