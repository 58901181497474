

const ProjectsDetailsData12 = (props) => {

  return (
    <>
      <div className="content">
        <div className="projects_ttl_box">
          <p className="ttl">グランリビオ芝大門</p>
          <p className="txt IBM">BRANDING</p>
          <p className="txt IBM">ADVERTISING</p>
          <p className="txt IBM">WEB</p>
        </div>
      </div>

      <div className="projects_box">
        <div className="img">
          <img src={`${process.env.PUBLIC_URL}/img/projects/grand-livio-shibadaimon/details_1.jpg`} alt="" />
        </div>
        <div className="img">
          <img src={`${process.env.PUBLIC_URL}/img/projects/grand-livio-shibadaimon/details_2.jpg`} alt="" />
        </div>
        <div className="img">
          <img src={`${process.env.PUBLIC_URL}/img/projects/grand-livio-shibadaimon/details_3.jpg`} alt="" />
        </div>
        <div className="img">
          <img src={`${process.env.PUBLIC_URL}/img/projects/grand-livio-shibadaimon/details_4.jpg`} alt="" />
        </div>
      </div>
    </>
  )

}

export default ProjectsDetailsData12;