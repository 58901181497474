

const ProjectsDetailsData13 = (props) => {

  return (
    <>
      <div className="content">
        <div className="projects_ttl_box">
          <p className="ttl">ザ・パークハウス 板橋大山大楠ノ杜</p>
          <p className="txt IBM">BRANDING</p>
          <p className="txt IBM">ADVERTISING</p>
          <p className="txt IBM">WEB</p>
          <p className="txt IBM">MOVIE</p>
        </div>
      </div>

      <div className="projects_box">
        <div className="img">
          <img src={`${process.env.PUBLIC_URL}/img/projects/parkhouse-itabashioyama-kusunomori/details_1.jpg`} alt="" />
        </div>
        <div className="img">
          <img src={`${process.env.PUBLIC_URL}/img/projects/parkhouse-itabashioyama-kusunomori/details_2.jpg`} alt="" />
        </div>
        <div className="img">
          <img src={`${process.env.PUBLIC_URL}/img/projects/parkhouse-itabashioyama-kusunomori/details_3.jpg`} alt="" />
        </div>
        <div className="youtube">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/1R74OpmNyq4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>
      </div>
    </>
  )

}

export default ProjectsDetailsData13;