import { motion } from "framer-motion";

const Thanks = (props) => {

  let sH = document.documentElement.clientHeight;
  const mainStyle = {
    height: (sH - 90) + 'px',
  };

  const getCookieArray = () => {
    var arr = [];
    if (document.cookie !== '') {
      var tmp = document.cookie.split('; ');
      for (var i = 0; i < tmp.length; i++) {
        var data = tmp[i].split('=');
        arr[data[0]] = decodeURIComponent(data[1]);
      }
    }
    return arr;
  }
  // リダイレクト
  var arr = getCookieArray();
  var value = arr['broadCookie'];
  if(value === "formSend"){
    document.cookie = "broadCookie=; max-age=0";
  }else{
    window.location.href = '/contact';
  }


  return (
    <>
      <motion.div
        className={props.pageTransition.className}
        style={props.pageTransition.style}
        animate={props.pageTransition.animate}
        initial={props.pageTransition.initial}
        exit={props.pageTransition.exit}
        transition={props.pageTransition.transition}
      >
        <main className="mainContact" style={mainStyle}>

          <section className="thanks_wrap">

            <div className="thanks_box">
              <p className="ttl IBM">THANKS.</p>
              <p className="lead">お問い合わせありがとうございます。<br />後日こちらよりご連絡いたします。</p>
            </div>

          </section>

        </main>
      </motion.div>
    </>
  );
}

export default Thanks;