// import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import {ProjectsList} from '../components/index';

const Projects = (props) => {

  let sH = document.documentElement.clientHeight;
  const mainStyle = {
    height: (sH-90)+'px',
  };

  return (
    <>
      <motion.div
        className={props.pageTransition.className}
        style={props.pageTransition.style}
        animate={props.pageTransition.animate}
        initial={props.pageTransition.initial}
        exit={props.pageTransition.exit}
        transition={props.pageTransition.transition}
      >
        <main className="mainProjects" style={mainStyle}>
          <section className="projects_wrap">

            <div className="projects_box">

              <ProjectsList 
                id={17}
                link={'/projects/details/?id=17'}
                img={`${process.env.PUBLIC_URL}/img/projects/baus-fuchu/thumbnail.jpg`}
                title={'バウス府中'}
              />
              <ProjectsList 
                id={16}
                link={'/projects/details/?id=16'}
                img={`${process.env.PUBLIC_URL}/img/projects/parkhouse-hikifune/thumbnail.jpg`}
                title={'ザ・パークハウス 曳舟'}
              />
              <ProjectsList 
                id={15}
                link={'/projects/details/?id=15'}
                img={`${process.env.PUBLIC_URL}/img/projects/prime-kawasaki/thumbnail.jpg`}
                title={'プライム川崎'}
              />
              <ProjectsList 
                id={14}
                link={'/projects/details/?id=14'}
                img={`${process.env.PUBLIC_URL}/img/projects/baus-mejiro-otomeyama/thumbnail.jpg`}
                title={'バウス目白御留山'}
              />
              <ProjectsList 
                link={'/projects/details/?id=13'}
                img={`${process.env.PUBLIC_URL}/img/projects/parkhouse-itabashioyama-kusunomori/thumbnail.jpg`}
                title={'ザ・パークハウス 板橋大山大楠ノ杜'}
              />
              <ProjectsList 
                link={'/projects/details/?id=18'}
                img={`${process.env.PUBLIC_URL}/img/projects/brillia-hiyoshi-sanchome/thumbnail.jpg`}
                title={'ブリリア日吉三丁目'}
              />
              <ProjectsList 
                link={'/projects/details/?id=12'}
                img={`${process.env.PUBLIC_URL}/img/projects/grand-livio-shibadaimon/thumbnail.jpg`}
                title={'グランリビオ芝大門'}
              />
              <ProjectsList 
                link={'/projects/details/?id=19'}
                img={`${process.env.PUBLIC_URL}/img/projects/proud-keikyu-kamata/thumbnail.jpg`}
                title={'プラウド京急蒲田'}
              />
              <ProjectsList 
                link={'/projects/details/?id=11'}
                img={`${process.env.PUBLIC_URL}/img/projects/parkhouse-itabashioyama/thumbnail.jpg`}
                title={'ザ・パークハウス 板橋大山'}
              />
              <ProjectsList 
                link={'/projects/details/?id=10'}
                img={`${process.env.PUBLIC_URL}/img/projects/prime-style-kawasaki/thumbnail.jpg`}
                title={'プライムスタイル川崎'}
              />
              <ProjectsList 
                link={'/projects/details/?id=9'}
                img={`${process.env.PUBLIC_URL}/img/projects/prime-hayama/thumbnail.jpg`}
                title={'プライム葉山'}
              />
              <ProjectsList 
                link={'/projects/details/?id=20'}
                img={`${process.env.PUBLIC_URL}/img/projects/proud-kawabatacho/thumbnail.jpg`}
                title={'プラウド川端町'}
              />
              <ProjectsList 
                link={'/projects/details/?id=8'}
                img={`${process.env.PUBLIC_URL}/img/projects/proud-takasaki-aramachi/thumbnail.jpg`}
                title={'プラウド高崎あら町'}
              />
              <ProjectsList 
                link={'/projects/details/?id=7'}
                img={`${process.env.PUBLIC_URL}/img/projects/prime-kawasaki-kozimashinden/thumbnail.jpg`}
                title={'プライム川崎小島新田'}
              />
              <ProjectsList 
                link={'/projects/details/?id=6'}
                img={`${process.env.PUBLIC_URL}/img/projects/prime-kanazawa-bunko/thumbnail.jpg`}
                title={'プライム金沢文庫'}
              />
              <ProjectsList 
                link={'/projects/details/?id=5'}
                img={`${process.env.PUBLIC_URL}/img/projects/gracia-life-kamiasao/thumbnail.jpg`}
                title={'グレーシアライフ上麻生'}
              />
              <ProjectsList 
                link={'/projects/details/?id=4'}
                img={`${process.env.PUBLIC_URL}/img/projects/majecity/thumbnail.jpg`}
                title={'マジェシティ'}
              />
              <ProjectsList 
                link={'/projects/details/?id=3'}
                img={`${process.env.PUBLIC_URL}/img/projects/residence-court-kichijoji/thumbnail.jpg`}
                title={'レジデンスコート吉祥寺'}
              />
              <ProjectsList 
                link={'/projects/details/?id=2'}
                img={`${process.env.PUBLIC_URL}/img/projects/corporate-advertising/thumbnail.jpg`}
                title={'Corporate Advertising'}
              />
              <ProjectsList 
                link={'/projects/details/?id=1'}
                img={`${process.env.PUBLIC_URL}/img/projects/shop-branding/thumbnail.jpg`}
                title={'Shop Branding'}
              />

            </div>

          </section>
        </main>
      </motion.div>
    </>
  );
}

export default Projects;