import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SwiperTop = (props) => {

  let w = window.innerWidth;
  let h = window.innerHeight;

  let slidesToShow = 6;
  if (w > 768 && w < h) {
    slidesToShow = 2.4;
  }else if (w <= 768) {
    if (h <= 812) {
      slidesToShow = 2.4;
    } else if (h <= 1024) {
      slidesToShow = 3;
    }
  }

  const settings = {
    autoplay: true, //自動でスクロール
    autoplaySpeed: 0, //自動再生のスライド切り替えまでの時間を設定
    speed: 7000, //スライドが流れる速度を設定
    cssEase: "linear", //スライドの流れ方を等速に設定
    slidesToShow: slidesToShow, //表示するスライドの数
    swipe: false, // 操作による切り替えはさせない
    arrows: false, //矢印非表示
    pauseOnFocus: false, //スライダーをフォーカスした時にスライドを停止させるか
    pauseOnHover: false, //スライダーにマウスホバーした時にスライドを停止させるか
    rtl: props.rtl
  };

  const images = props.images;
  const imagesObverse = props.imagesObverse;
  const imagesReverse = props.imagesReverse;
  // console.log(typeof(images));
  if ((w > 768 && w < h) || (w <= 768)) {
    images.length = 5;
    imagesObverse.length = 5;
    imagesReverse.length = 5;
  }
  // else if (w <= 768) {
  // }

  return (
    <>
      <div className="swiper-wrapper">
        <Slider {...settings} dir="rtl">
          {images.map((img, i) =>
            <div className="slide_list" key={i}>
              <div className="slide_cover">
                <img src={process.env.PUBLIC_URL + img} alt="" />
                <Link to={props.linkObverse} className="imgObverse">
                  <div className="img">
                    <img src={process.env.PUBLIC_URL + imagesObverse[i]} alt="" />
                  </div>
                </Link>
                <Link to={props.linkReverse} className="imgReverse">
                  <div className="img">
                    <img src={process.env.PUBLIC_URL + imagesReverse[i]} alt="" />
                  </div>
                </Link>
              </div>
            </div>
          )}
        </Slider>
      </div>
    </>
  )

}

export default SwiperTop;