

const ProjectsDetailsData11 = (props) => {

  return (
    <>
      <div className="content">
        <div className="projects_ttl_box">
          <p className="ttl">ザ・パークハウス 板橋大山</p>
          <p className="txt IBM">BRANDING</p>
          <p className="txt IBM">ADVERTISING</p>
          <p className="txt IBM">WEB</p>
        </div>
      </div>

      <div className="projects_box">
        <div className="img">
          <img src={`${process.env.PUBLIC_URL}/img/projects/parkhouse-itabashioyama/details_1.jpg`} alt="" />
        </div>
        <div className="img">
          <img src={`${process.env.PUBLIC_URL}/img/projects/parkhouse-itabashioyama/details_2.jpg`} alt="" />
        </div>
        <div className="img">
          <img src={`${process.env.PUBLIC_URL}/img/projects/parkhouse-itabashioyama/details_3.jpg`} alt="" />
        </div>
        <div className="img">
          <img src={`${process.env.PUBLIC_URL}/img/projects/parkhouse-itabashioyama/details_4.jpg`} alt="" />
        </div>
      </div>
    </>
  )

}

export default ProjectsDetailsData11;