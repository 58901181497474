import { useState } from "react";
import { Link } from "react-router-dom";
import { HeaderNav } from './index'

const Header = (props) => {

  const [open, setOpen] = useState(false)
  const toggle = () => {
    setOpen(!open)
  }


  return (
    <header>
      <div className="header">

        <Link to='/' className="logo">
          <img src={`${process.env.PUBLIC_URL}/img/common/logo.svg`} alt="" />
        </Link>

        <HeaderNav
          open={open}
        />

        <div className={`btn-trigger ${open ? 'active' : ''}`} id="navTrigger" onClick={toggle}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </header>
  );
}

export default Header;