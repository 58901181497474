

const ProjectsDetailsData1 = (props) => {

  return (
    <>
      <div className="content">
        <div className="projects_ttl_box">
          <p className="ttl">SHOP</p>
          <p className="txt IBM">BRANDING</p>
        </div>
      </div>

      <div className="projects_box">
        <div className="img">
          <p className="ttl">SHOP CARD  ［ 飲食店 ］</p>
          <img src={`${process.env.PUBLIC_URL}/img/projects/shop-branding/details_1.jpg`} alt="" />
        </div>
        <div className="img">
        <p className="ttl">SHOP SITE  ［ レンタルドレス ］</p>
          <img src={`${process.env.PUBLIC_URL}/img/projects/shop-branding/details_2.jpg`} alt="" />
        </div>
        <div className="img">
        <p className="ttl">SHOP BAG  ［ 植物園 ］</p>
          <img src={`${process.env.PUBLIC_URL}/img/projects/shop-branding/details_3.jpg`} alt="" />
        </div>
        <div className="img">
        <p className="ttl">FLOWER GIFT  ［ フラワーショップ ］</p>
          <img src={`${process.env.PUBLIC_URL}/img/projects/shop-branding/details_4.jpg`} alt="" />
        </div>
      </div>
    </>
  )

}

export default ProjectsDetailsData1;