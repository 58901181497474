import { motion } from "framer-motion";
import { Form } from '../components/index';

const Message = (props) => {

  let sH = document.documentElement.clientHeight;
  const mainStyle = {
    height: (sH - 90) + 'px',
  };

  return (
    <>
      <motion.div
        className={props.pageTransition.className}
        style={props.pageTransition.style}
        animate={props.pageTransition.animate}
        initial={props.pageTransition.initial}
        exit={props.pageTransition.exit}
        transition={props.pageTransition.transition}
      >
        <main className="mainContact" style={mainStyle}>

          <section className="contact_wrap">

            <div className="contact_box">
              <Form />
            </div>

          </section>

        </main>
      </motion.div>
    </>
  );
}

export default Message;