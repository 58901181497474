import { motion } from "framer-motion";

const Message = (props) => {

  let sH = document.documentElement.clientHeight;
  const mainStyle = {
    height: (sH - 90) + 'px',
  };

  return (
    <>
      <motion.div
        className={props.pageTransition.className}
        style={props.pageTransition.style}
        animate={props.pageTransition.animate}
        initial={props.pageTransition.initial}
        exit={props.pageTransition.exit}
        transition={props.pageTransition.transition}
      >
        <main className="mainMessage" style={mainStyle}>

          <section className="message_wrap">

            <div className="message_box">
              <p className="p1">
                BROADは、新たな幅広い視野と多角的な着眼点で<br />
                “そのモノのキモ（資質）は何か”その声に耳を傾け、<br />
                “資質を最大限引き出すブランドづくり”にこだわります。<br />
                革新が日常的に進む時代、私たちができることを常に考え、<br />
                エンドユーザーの心理を読み解き、<br />
                共感から拡散へと話題性ある広告コンテンツを展開し、<br />
                記憶にも記録にも残るようなモノづくりに<br />
                真摯に取り組んでいきます。
              </p>
              <p className="p2">株式会社BROAD<br />代表取締役　岡崎 正広</p>
            </div>

          </section>

        </main>
      </motion.div>
    </>
  );
}

export default Message;