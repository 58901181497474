import { useEffect } from 'react';
import { FormTextField, FormTextArea, FormBtn } from '../components/index';

const Form = (props) => {

  useEffect(() => {
    //form
    const form = document.getElementById("form");
    //form element
    const name = document.getElementById("name");
    const email = document.getElementById("mail");
    const inquiry = document.getElementById("inquiry");
    //error message
    const name_error_message = document.getElementById("name-error-message")
    const email_error_message = document.getElementById("email-error-message")
    const inquiry_error_message = document.getElementById("inquiry-error-message")
    //button
    const btn = document.getElementById("submitBtn");

    //バリデーションパターン
    const nameExp = /./;
    const emailExp = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/;
    const inquiryExp = /./;

    //初期状態設定
    btn.style.pointerEvents = ('none');

    //name
    name.addEventListener("focusout", e => {
      if (nameExp.test(name.value)) {
        name_error_message.style.display = "none";
      } else {
        name_error_message.style.display = "inline";
      }
    })
    name.addEventListener("keyup", e => {
      if (nameExp.test(name.value)) {
        name.classList.remove("error");
        name.classList.add("success");
      } else {
        name.classList.remove("success");
        name.classList.add("error");
      }
      checkSuccess();
    })

    //email
    email.addEventListener("focusout", e => {
      if (emailExp.test(email.value)) {
        email_error_message.style.display = "none";
      } else {
        email_error_message.style.display = "inline";
      }
    })
    email.addEventListener("keyup", e => {
      if (emailExp.test(email.value)) {
        email.classList.remove("error");
        email.classList.add("success");
      } else {
        email.classList.remove("success");
        email.classList.add("error");
      }
      checkSuccess();
    })

    //inquiry
    inquiry.addEventListener("focusout", e => {
      if (inquiryExp.test(inquiry.value)) {
        inquiry_error_message.style.display = "none";
      } else {
        inquiry_error_message.style.display = "inline";
      }
    })
    inquiry.addEventListener("keyup", e => {
      if (inquiryExp.test(inquiry.value)) {
        inquiry.classList.remove("error");
        inquiry.classList.add("success");
      } else {
        inquiry.classList.remove("success");
        inquiry.classList.add("error");
      }
      checkSuccess();
    })

    //ボタンのdisabled制御
    const checkSuccess = () => {
      if (name.value && email.value && inquiry.value) {
        if (
          name.getAttribute("class").includes("success") &&
          email.getAttribute("class").includes("success") &&
          inquiry.getAttribute("class").includes("success")
        ) {
          btn.style.pointerEvents = ('auto');
          btn.disabled = false;
        } else {
          btn.style.pointerEvents = ('none');
          btn.disabled = true;
        }
      }
    }

    //submit
    btn.addEventListener("click", e => {
      e.preventDefault();
      form.method = "post";
      form.action = "/form/send.php";
      form.submit();
    })
    form.addEventListener("keydown", e => {
      // console.log(e);
      if ((e.key === 'Enter') || (e.code === 13)) {
        if (e.target.type !== 'submit' &&
          e.target.type !== 'textarea') {
          e.preventDefault();
          return false;
        }
      }
    })
  }, [])

  return (
    <>
      <form id="form">

        <div className="field_box">
          <FormTextField
            id={"name"}
            name={"name"}
            label={"NAME"}
            placeholder={"お名前"}
          />
          <span id="name-error-message">「お名前」は必ず入力してください。</span>
        </div>

        <div className="field_box">
          <FormTextField
            id={"mail"}
            name={"mail"}
            label={"MAIL"}
            placeholder={"メールアドレス"}
          />
          <span id="email-error-message">「メールアドレス」は正しい形式で入力してください。</span>
        </div>

        <div className="field_box">
          <FormTextArea
            id={"inquiry"}
            name={"inquiry"}
            label={"INQUIRY"}
            placeholder={"お問い合わせ内容"}
          />
          <span id="inquiry-error-message">「お問い合わせ内容」は必ず入力してください。</span>
        </div>

        <div className="btn_box">
          <FormBtn
            id={"submitBtn"}
            type={"submitBtn"}
            name={"submitBtn"}
          />
        </div>

      </form>
    </>
  );
}

export default Form;