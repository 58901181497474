import { useEffect } from 'react';
import Lottie from 'react-lottie'
import animationData from './LoadTopLottie.json'

const LoadTop = (props) => {

  let sH = document.documentElement.clientHeight;
  const mainStyle = {
    height: (sH - 90) + 'px',
  };

  useEffect(() => {
    let startLoadWrap = document.getElementById('startLoadWrap');
    // console.log(startLoadWrap);
    const addNoActive = () => {
      startLoadWrap.style.marginTop = (-sH + 90)+'px';
    }
    setTimeout(addNoActive, 2000);
  })

  const defaultOptions = {
    // loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };


  return (
    <>
      <div className="start_load_wrap" id="startLoadWrap" style={mainStyle}>

        <div className="svg_box">
          <Lottie options={defaultOptions}
          />
        </div>

      </div>
    </>
  )

}

export default LoadTop;