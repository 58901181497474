import { Link } from "react-router-dom";

const ProjectsList = (props) => {

  return (
    <>
      <div className="list">
        <Link to={props.link} className="img">
          <img src={props.img} alt="" />
        </Link>
        <p>{props.title}</p>
      </div>
    </>
  )

}

export default ProjectsList;