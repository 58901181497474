

const ProjectsDetailsData20 = (props) => {

  return (
    <>
      <div className="content">
        <div className="projects_ttl_box">
          <p className="ttl">プラウド川端町</p>
          <p className="txt IBM">BRANDING</p>
          <p className="txt IBM">ADVERTISING</p>
        </div>
      </div>

      <div className="projects_box">
        <div className="img">
          <img src={`${process.env.PUBLIC_URL}/img/projects/proud-kawabatacho/details_1.jpg`} alt="" />
        </div>
      </div>
    </>
  )

}

export default ProjectsDetailsData20;