import { Link } from "react-router-dom";

const HeaderNavLink = (props) => {
  return (
    <li  className={props.class}>
      <Link to={props.link}>
        <span className="IBM">{props.title}</span>
      </Link>
    </li>
  );
}

export default HeaderNavLink;