

const ProjectsDetailsData2 = (props) => {

  return (
    <>
      <div className="content">
        <div className="projects_ttl_box">
          <p className="ttl">企業広告 ／ 三信住建株式会社</p>
          <p className="txt IBM">NEWSPAPER（Nikkei）</p>
        </div>
      </div>

      <div className="projects_box">
        <div className="img">
          <img src={`${process.env.PUBLIC_URL}/img/projects/corporate-advertising/details_1.jpg`} alt="" />
        </div>
      </div>
    </>
  )

}

export default ProjectsDetailsData2;