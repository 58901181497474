

const ProjectsDetailsData5 = (props) => {

  return (
    <>
      <div className="content">
        <div className="projects_ttl_box">
          <p className="ttl">グレーシアライフ上麻生</p>
          <p className="txt IBM">BRANDING</p>
          <p className="txt IBM">ADVERTISING</p>
          <p className="txt IBM">WEB</p>
        </div>
      </div>

      <div className="projects_box">
        <div className="img">
          <img src={`${process.env.PUBLIC_URL}/img/projects/gracia-life-kamiasao/details_1.jpg`} alt="" />
        </div>
        <div className="img">
          <img src={`${process.env.PUBLIC_URL}/img/projects/gracia-life-kamiasao/details_2.jpg`} alt="" />
        </div>
        <div className="img">
          <img src={`${process.env.PUBLIC_URL}/img/projects/gracia-life-kamiasao/details_3.jpg`} alt="" />
        </div>
        <div className="img">
          <img src={`${process.env.PUBLIC_URL}/img/projects/gracia-life-kamiasao/details_4.jpg`} alt="" />
        </div>
      </div>
    </>
  )

}

export default ProjectsDetailsData5;