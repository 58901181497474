import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { Top, Projects, ProjectsDetails, Message, Company, Contact, Thanks, page404 } from './pages/index';
import { Head, Header } from './inc/index';

const App = () => {

  let sH = document.documentElement.clientHeight;

  const pageTransition = {
    className: "mainWrap",
    style: {
      position: "absolute",
      left: 0,
      width: 100 + "%",
      height: (sH - 90) + "px",
      overflow: "hidden",
    },
    animate: {
      top: 90,
    },
    initial: {
      top: (sH + 90) + "px",
    },
    exit: {
      top: -(sH - 90) + "px",
    },
    transition: { duration: 1.0, ease: [0.79, 0.14, 0.15, 0.86] },
  }

  return (
    <>
      <Router>
        <Head />
        <Header />
        <Route
          render={({ location }) => (
            <AnimatePresence exitBeforeEnter={false} initial={true}>
              <Switch location={location} key={location.pathname}>
                <Route exact path="/" render={() => <Top pageTransition={pageTransition} />} />
                <Route exact path="/projects/details" render={() => <ProjectsDetails pageTransition={pageTransition} />} />
                <Route exact path="/projects" render={() => <Projects pageTransition={pageTransition} />} />
                <Route exact path="/message" render={() => <Message pageTransition={pageTransition} />} />
                <Route exact path="/company" render={() => <Company pageTransition={pageTransition} />} />
                <Route exact path="/contact" render={() => <Contact pageTransition={pageTransition} />} />
                <Route exact path="/thanks" render={() => <Thanks pageTransition={pageTransition} />} />
                {/* <Route render={() => <page404 pageTransition={pageTransition} />} /> */}
                <Route component={page404} />
              </Switch>
            </AnimatePresence>
          )}
        />
      </Router>
    </>
  );
}

export default App;