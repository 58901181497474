import { useLocation } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async'

const Head = (props) => {

  let location = useLocation().pathname;
  // console.log(location);
  let title = ""
  if(location === "/"){
    title = "株式会社BROAD";
  }else if(location === "/projects"){
    title = "PROJECTS｜株式会社BROAD";
  }else if(location === "/projects/details/"){
    title = "PROJECTS｜株式会社BROAD";
  }else if(location === "/message"){
    title = "MESSAGE｜株式会社BROAD";
  }else if(location === "/company"){
    title = "COMPANY｜株式会社BROAD";
  }else if(location === "/contact"){
    title = "CONTACT｜株式会社BROAD";
  }else if(location === "/thanks"){
    title = "THANKS｜株式会社BROAD";
  }

  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
      </Helmet>
    </HelmetProvider>
  );
}

export default Head;