import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(() =>
  createStyles({
    "root": {
      width: 400,
      fontSize: 10,
      '& label.Mui-focused': {
        color: '#231815',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#231815',
      },
    }
  })
);

const FormTextArea = (props) => {

  const classes = useStyles();

  return (
    <>
      <TextField variant="standard" multiline
        className={classes.root}
        id={props.id}
        name={props.name}
        label={props.label}
        placeholder={props.placeholder}
        inputProps={{style: {fontSize: 14}}}
      />
    </>
  );
}

export default FormTextArea;