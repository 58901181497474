import { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import { LoadTop, SwiperTop } from '../components/index';

const Top = (props) => {

  let history = useHistory().length;
  console.log(history);

  let sH = window.innerHeight;
  const mainStyle = {
    height: (sH - 90) + 'px',
  };

  useEffect(() => {

    let timer1,timer2,timer3,timer4,timer5,timer6;

    let unmounted = false;

    if (!unmounted) {
      // console.log(unmounted);

      const slideCover = document.getElementsByClassName("slide_cover");

      /** 重複チェック用配列 */
      let randoms = [];
      /** 最小値と最大値 */
      let min = 0, max = slideCover.length;
      /** min以上max以下の整数値の乱数を返す */
      const intRandom = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }
      /** 重複チェックしながら乱数作成 */
      for (let i = min; i < max; i++) {
        while (true) {
          let tmp = intRandom(min, max - 1);
          if (!randoms.includes(tmp)) {
            randoms.push(tmp);
            break;
          }
        }
      }
      // console.log(randoms);


      const addClass = (targetElm) => {
        for (let i = 0; i < randoms.length; i++) {
          (function (pram) {
            setTimeout(function () {
              targetElm[randoms[pram]].classList.remove("reverse");
              targetElm[randoms[pram]].classList.add("obverse");
            }, pram * 10);
          })(i);
        }
      }
      const removeClass = (targetElm) => {
        for (let i = 0; i < randoms.length; i++) {
          (function (pram) {
            setTimeout(function () {
              targetElm[randoms[pram]].classList.remove("obverse");
              targetElm[randoms[pram]].classList.add("reverse");
            }, pram * 10);
          })(i);
        }
      }


      const slideCoverOn = () => {
        addClass(slideCover);
        timer3 = setTimeout(removeClass, 10000, slideCover);
        timer4 = setTimeout(slideCoverOnRe, 20000);
      }
      const slideCoverOnRe = () => {
        addClass(slideCover);
        timer5 = setTimeout(removeClass, 10000, slideCover);
        timer6 = setTimeout(slideCoverOn, 20000);
      }


      if (history === 1 || history === 2) {
        timer1 = setTimeout(slideCoverOn, 2700);
      } else {
        timer2 = setTimeout(slideCoverOn, 100);
      }

    }

    return () => { 
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
      clearTimeout(timer4);
      clearTimeout(timer5);
      clearTimeout(timer6);
      unmounted = true; 
      // console.log(unmounted);
    }
  }, [])

  return (
    <>
      <motion.div
        className={props.pageTransition.className}
        style={props.pageTransition.style}
        animate={props.pageTransition.animate}
        initial={props.pageTransition.initial}
        exit={props.pageTransition.exit}
        transition={props.pageTransition.transition}
      >

        {(() => {
          if (history === 1 || history === 2) {
            return <LoadTop />
          }
        })()}

        <main className="mainTop" style={mainStyle}>
          <section className="projects_wrap">

            <div
              id="slidNum1"
              className="slide_box"
              style={mainStyle}
            >

              <div className="slide">
                <SwiperTop
                  rtl={false}
                  images={
                    [
                      "/img/top/slide.png",
                      "/img/top/slide.png",
                      "/img/top/slide.png",
                      "/img/top/slide.png",
                      "/img/top/slide.png",
                      "/img/top/slide.png",
                      "/img/top/slide.png",
                      "/img/top/slide.png",
                      "/img/top/slide.png",
                      "/img/top/slide.png",
                    ]
                  }
                  linkObverse={"/projects/details/?id=14"}
                  imagesObverse={
                    [
                      "/img/projects/baus-mejiro-otomeyama/top_slide_1.jpg",
                      "/img/projects/baus-mejiro-otomeyama/top_slide_2.jpg",
                      "/img/projects/baus-mejiro-otomeyama/top_slide_3.jpg",
                      "/img/projects/baus-mejiro-otomeyama/top_slide_4.jpg",
                      "/img/projects/baus-mejiro-otomeyama/top_slide_5.jpg",
                      "/img/projects/baus-mejiro-otomeyama/top_slide_1.jpg",
                      "/img/projects/baus-mejiro-otomeyama/top_slide_2.jpg",
                      "/img/projects/baus-mejiro-otomeyama/top_slide_3.jpg",
                      "/img/projects/baus-mejiro-otomeyama/top_slide_4.jpg",
                      "/img/projects/baus-mejiro-otomeyama/top_slide_5.jpg",
                    ]
                  }
                  linkReverse={"/projects/details/?id=10"}
                  imagesReverse={
                    [
                      "/img/projects/prime-style-kawasaki/top_slide_1.jpg",
                      "/img/projects/prime-style-kawasaki/top_slide_2.jpg",
                      "/img/projects/prime-style-kawasaki/top_slide_3.jpg",
                      "/img/projects/prime-style-kawasaki/top_slide_4.jpg",
                      "/img/projects/prime-style-kawasaki/top_slide_5.jpg",
                      "/img/projects/prime-style-kawasaki/top_slide_1.jpg",
                      "/img/projects/prime-style-kawasaki/top_slide_2.jpg",
                      "/img/projects/prime-style-kawasaki/top_slide_3.jpg",
                      "/img/projects/prime-style-kawasaki/top_slide_4.jpg",
                      "/img/projects/prime-style-kawasaki/top_slide_5.jpg",
                    ]
                  }
                />
              </div>

              <div className="slide">
                <SwiperTop
                  rtl={true}
                  images={
                    [
                      "/img/top/slide.png",
                      "/img/top/slide.png",
                      "/img/top/slide.png",
                      "/img/top/slide.png",
                      "/img/top/slide.png",
                      "/img/top/slide.png",
                      "/img/top/slide.png",
                      "/img/top/slide.png",
                      "/img/top/slide.png",
                      "/img/top/slide.png",
                    ]
                  }
                  linkObverse={"/projects/details/?id=12"}
                  imagesObverse={
                    [
                      "/img/projects/grand-livio-shibadaimon/top_slide_1.jpg",
                      "/img/projects/grand-livio-shibadaimon/top_slide_2.jpg",
                      "/img/projects/grand-livio-shibadaimon/top_slide_3.jpg",
                      "/img/projects/grand-livio-shibadaimon/top_slide_4.jpg",
                      "/img/projects/grand-livio-shibadaimon/top_slide_5.jpg",
                      "/img/projects/grand-livio-shibadaimon/top_slide_1.jpg",
                      "/img/projects/grand-livio-shibadaimon/top_slide_2.jpg",
                      "/img/projects/grand-livio-shibadaimon/top_slide_3.jpg",
                      "/img/projects/grand-livio-shibadaimon/top_slide_4.jpg",
                      "/img/projects/grand-livio-shibadaimon/top_slide_5.jpg",
                    ]
                  }
                  linkReverse={"/projects/details/?id=5"}
                  imagesReverse={
                    [
                      "/img/projects/gracia-life-kamiasao/top_slide_1.jpg",
                      "/img/projects/gracia-life-kamiasao/top_slide_2.jpg",
                      "/img/projects/gracia-life-kamiasao/top_slide_3.jpg",
                      "/img/projects/gracia-life-kamiasao/top_slide_4.jpg",
                      "/img/projects/gracia-life-kamiasao/top_slide_5.jpg",
                      "/img/projects/gracia-life-kamiasao/top_slide_1.jpg",
                      "/img/projects/gracia-life-kamiasao/top_slide_2.jpg",
                      "/img/projects/gracia-life-kamiasao/top_slide_3.jpg",
                      "/img/projects/gracia-life-kamiasao/top_slide_4.jpg",
                      "/img/projects/gracia-life-kamiasao/top_slide_5.jpg",
                    ]
                  }
                />
              </div>

              <div className="slide">
                <SwiperTop
                  rtl={false}
                  images={
                    [
                      "/img/top/slide.png",
                      "/img/top/slide.png",
                      "/img/top/slide.png",
                      "/img/top/slide.png",
                      "/img/top/slide.png",
                      "/img/top/slide.png",
                      "/img/top/slide.png",
                      "/img/top/slide.png",
                      "/img/top/slide.png",
                      "/img/top/slide.png",
                    ]
                  }
                  linkObverse={"/projects/details/?id=13"}
                  imagesObverse={
                    [
                      "/img/projects/parkhouse-itabashioyama-kusunomori/top_slide_1.jpg",
                      "/img/projects/parkhouse-itabashioyama-kusunomori/top_slide_2.jpg",
                      "/img/projects/parkhouse-itabashioyama-kusunomori/top_slide_3.jpg",
                      "/img/projects/parkhouse-itabashioyama-kusunomori/top_slide_4.jpg",
                      "/img/projects/parkhouse-itabashioyama-kusunomori/top_slide_5.jpg",
                      "/img/projects/parkhouse-itabashioyama-kusunomori/top_slide_1.jpg",
                      "/img/projects/parkhouse-itabashioyama-kusunomori/top_slide_2.jpg",
                      "/img/projects/parkhouse-itabashioyama-kusunomori/top_slide_3.jpg",
                      "/img/projects/parkhouse-itabashioyama-kusunomori/top_slide_4.jpg",
                      "/img/projects/parkhouse-itabashioyama-kusunomori/top_slide_5.jpg",
                    ]
                  }
                  linkReverse={"/projects/details/?id=11"}
                  imagesReverse={
                    [
                      "/img/projects/parkhouse-itabashioyama/top_slide_1.jpg",
                      "/img/projects/parkhouse-itabashioyama/top_slide_2.jpg",
                      "/img/projects/parkhouse-itabashioyama/top_slide_3.jpg",
                      "/img/projects/parkhouse-itabashioyama/top_slide_4.jpg",
                      "/img/projects/parkhouse-itabashioyama/top_slide_5.jpg",
                      "/img/projects/parkhouse-itabashioyama/top_slide_1.jpg",
                      "/img/projects/parkhouse-itabashioyama/top_slide_2.jpg",
                      "/img/projects/parkhouse-itabashioyama/top_slide_3.jpg",
                      "/img/projects/parkhouse-itabashioyama/top_slide_4.jpg",
                      "/img/projects/parkhouse-itabashioyama/top_slide_5.jpg",
                    ]
                  }
                />
              </div>

            </div>

          </section>
        </main>
      </motion.div>
    </>
  );
}

export default Top;