import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() =>
  createStyles({
    "root": {
      padding: "12px 40px",
    }
  })
);

const FormBtn = (props) => {

  const screenLock = () => {
    // ロック用のdivを生成
    let element = document.createElement('div');
    element.id = "screenLock";
    // ロック用のスタイル
    element.style.height = '100%';
    element.style.left = '0px';
    element.style.position = 'fixed';
    element.style.top = '0px';
    element.style.width = '100%';
    element.style.zIndex = '99999999';
    element.style.opacity = '0';

    let objBody = document.getElementsByTagName("body").item(0);
    objBody.appendChild(element);
  }

  const classes = useStyles();

  return (
    <>
      <Button variant="contained"
        id={props.id}
        type={props.type}
        name={props.name}
        className={classes.root}
        onClick={screenLock}
      >
        SEND
      </Button>
    </>
  );
}

export default FormBtn;