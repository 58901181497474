

const ProjectsDetailsData16 = (props) => {

  return (
    <>
      <div className="content">
        <div className="projects_ttl_box">
          <p className="ttl">ザ・パークハウス 曳舟</p>
          <p className="txt IBM">BRANDING</p>
          <p className="txt IBM">ADVERTISING</p>
          <p className="txt IBM">WEB</p>
          <p className="txt IBM">CONCEPT MOVIE</p>
        </div>
      </div>

      <div className="projects_box">
        <div className="img mb-10">
          <img src={`${process.env.PUBLIC_URL}/img/projects/parkhouse-hikifune/details_1.jpg`} alt="" />
        </div>
        <div className="img mb-10">
          <img src={`${process.env.PUBLIC_URL}/img/projects/parkhouse-hikifune/details_2.jpg`} alt="" />
        </div>
        <div className="img mb-10">
          <img src={`${process.env.PUBLIC_URL}/img/projects/parkhouse-hikifune/details_3.jpg`} alt="" />
        </div>
        <div className="img mb-10">
          <img src={`${process.env.PUBLIC_URL}/img/projects/parkhouse-hikifune/details_4.jpg`} alt="" />
        </div>
        <div className="img">
          <img src={`${process.env.PUBLIC_URL}/img/projects/parkhouse-hikifune/details_5.jpg`} alt="" />
        </div>
        <div className="youtube">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/JBqsJXZVHvM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>
      </div>
    </>
  )

}

export default ProjectsDetailsData16;