// import { motion } from "framer-motion";

const page404 = (props) => {
  return (
    <>
      {/* <motion.div
        className={props.pageTransition.className}
        animate={props.pageTransition.animate}
        initial={props.pageTransition.initial}
        exit={props.pageTransition.exit}
        transition={props.pageTransition.transition}
      > */}
        <main>404</main>
      {/* </motion.div> */}
    </>
  );
}

export default page404;