import { motion } from "framer-motion";

const Company = (props) => {

  let sH = document.documentElement.clientHeight;
  const mainStyle = {
    height: (sH - 90) + 'px',
  };

  return (
    <>
      <motion.div
        className={props.pageTransition.className}
        style={props.pageTransition.style}
        animate={props.pageTransition.animate}
        initial={props.pageTransition.initial}
        exit={props.pageTransition.exit}
        transition={props.pageTransition.transition}
      >
        <main className="mainCompany" style={mainStyle}>

          <section className="company_wrap">

            <div className="company_box">

              <div className="img_box" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/img/company/company.jpg'})` }}></div>

              <div className="data_box">
                <div className="top_box">
                  <div className="name_box">
                    <p className="p1">株式会社BROAD</p>
                    <p className="p2">事業内容</p>
                  </div>
                  <div className="business_box">
                    <p>ブランディング・デザイン事業<br /><span>新規プロジェクト、ブランドの企画及びコンセプトワーク・<br />プロモーション等の総合プロデュース</span></p>
                    <p>撮影・映像ディレクション事業</p>
                    <p>住宅関連商品の開発事業</p>
                  </div>
                </div>
                <div className="btm_box">
                  <div className="address_box">
                    <p className="p1">所在地</p>
                    <p className="p2">〒100-0004 東京都千代田区大手町1-6-1<br />大手町ビル1F, 2F, 3F  SPACES大手町<br /><a href="tel:03-6822-3360">TEL：03-6822-3360</a></p>
                  </div>
                  <div className="map_box">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3240.6178821024064!2d139.7599377!3d35.6864098!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188c0795dc61fd%3A0x77de4804ccd3d437!2z44K544Oa44O844K344K6IOWkp-aJi-eUuu-9nFNwYWNlcyDlpKfmiYvnlLo!5e0!3m2!1sja!2sjp!4v1684424914982!5m2!1sja!2sjp" frameBorder="0" title="googlemap"></iframe>
                  </div>
                  <div className="access_box">
                    <p className="p1">最寄り駅からのアクセス</p>
                    <p className="p2">東京メトロ丸ノ内線・千代田線・半蔵門線・東西線・都営地下鉄三田線<br />「大手町」駅 直結 徒歩0分<br />JR各線・新幹線 「東京」駅　徒歩8分</p>
                  </div>
                </div>
              </div>

            </div>

          </section>
        </main>
      </motion.div>
    </>
  );
}

export default Company;