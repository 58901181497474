import { useLocation } from "react-router-dom";
import { HeaderNavLink } from './index'

const HeaderNav = (props) => {
  // console.log(props.open);
  let location = useLocation().pathname;
  // console.log(location);
  let navClass = ""
  if(location === "/"){
    navClass = "top"
  }else if(location === "/projects"){
    navClass = "projects"
  }else if(location === "/projects/details/"){
    navClass = "projects"
  }else if(location === "/message"){
    navClass = "message"
  }else if(location === "/company"){
    navClass = "company"
  }else if(location === "/contact"){
    navClass = "contact"
  }else if(location === "/thanks"){
    navClass = "thanks"
  }

  return (
    <nav id="nav" className={`${navClass} ${props.open ? 'active' : ''}`}>
      <ul>
        <HeaderNavLink
          link={'/'}
          class={'top'}
          title={'TOP'}
        />
        <HeaderNavLink
          link={'/projects'}
          class={'projects'}
          title={'PROJECTS'}
        />
        <HeaderNavLink
          link={'/message'}
          class={'message'}
          title={'MESSAGE'}
        />
        <HeaderNavLink
          link={'/company'}
          class={'company'}
          title={'COMPANY'}
        />
        <HeaderNavLink
          link={'/contact'}
          class={'contact'}
          title={'CONTACT'}
        />
      </ul>
    </nav>
  );
}

export default HeaderNav;